<template>
  <!-- eslint-disable -->
  <div class="content">
    <PageHeader :breadcrumbLinks="breadcrumbLinks" title="WhatsApp" :subtitle="'Templates'" />
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-md-4 col-lg-4 col-sm-12">
          <form @submit.prevent="fetchTemplates(1)" class="form-group">
            <div class="input-group">
              <input placeholder="" type="text" class="form-control search-form" v-model="searchQuery"
                ref="searchInput"  @keydown="replaceSpaceWithUnderscore" />
              <div class="input-group-prepend">
                <button class="btn icon_btn m-0">
                  <span class="material-symbols-outlined"> search </span>
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-4 col-lg-4 col-sm-12">
          <div class="input-group">
            <div class="input-group-prepend w-100">
              <select class="form-control" @change="changeNumber()" style="margin-left: 5px;" v-model="lastWAI">
                <option v-for="number in whatsNumbers" :key="number.id"
                  :value="getNumberWhatsappIdentification(number)">{{
                    number.phone_number | phone }} ({{ number.name }})</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-6 col-sm-12">
          <div class="row mb-4">
            <div class="col-md-8 col-lg-8 col-sm-12 all-actions">
              <div class="action">
                <div class="control-group opt-2 to-left">
                  <label class="control control-checkbox" :for="'template-checka-all'">
                    <input v-model="selectAllTemplates" type="checkbox" class="custom-control-input"
                      :id="'template-checka-all'" />
                    <div class="control_indicator"></div>
                  </label>
                </div>
                {{ $t("generic-str.all") }}
              </div>
              <div class="action">
                <button @click="deleteAll" class="btn-delete-all">
                  <span class="material-symbols-outlined to-left">delete_forever</span>
                  <span>
                    {{ selectedTemplates.length }}
                    Selecionados
                  </span>
                </button>
              </div>
            </div>
            <div class="col-md-4 col-lg-4 col-sm-12">
              <div class="input-group">
                <div class="">
                  <router-link :to="'/whatsapp/jv/templates/new'" class="btn btn-primary btn-add-template">
                    <span class="material-symbols-outlined">add_circle</span><span class="span-text">{{
                      $t('sms.templates.lbl-create') }}</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div v-if="fetched">
            <div class="accordion accordion-wpp-template" role="tablist">
              <ButtonComponent class="mr-2" :variant="templateCategory === '' ? 'primary' : 'secondary'"
                @click="changeCategory('')">
                Todos
              </ButtonComponent>
              <ButtonComponent class="mr-2" :variant="templateCategory === 'MARKETING' ? 'primary' : 'secondary'"
                @click="changeCategory('MARKETING')">
                <span class="material-symbols-outlined">campaign</span>
              </ButtonComponent>
              <ButtonComponent class="mr-2" :variant="templateCategory === 'UTILITY' ? 'primary' : 'secondary'"
                @click="changeCategory('UTILITY')">
                <span class="material-symbols-outlined">notifications</span>
              </ButtonComponent>
              <ButtonComponent :variant="templateCategory === 'AUTHENTICATION' ? 'primary' : 'secondary'"
                @click="changeCategory('AUTHENTICATION')">
                <span class="material-symbols-outlined">key</span>
              </ButtonComponent>
            </div>
          </div>
          <div v-if="fetched">
            <div class="card card-templates" v-for="(list, i) in templates" :key="list.id">
              <div class="card-header">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-10 col-sm-11 template-head-infos p-0">
                      <div class="row">
                        <div class="control-group opt-2 col-md-1 col-sm-1 col-lg-1">
                          <label class="control control-checkbox" :for="'template-check' + i">
                            <input v-model="selectedTemplates" type="checkbox" class="custom-control-input"
                              :id="'template-check' + i" :value="list" />
                            <div class="control_indicator"></div>
                          </label>
                        </div>
                        <span class="col-lg-6 col-md-6 col-sm-10"><b class="mr-1">{{ $t('send-component.title') }}:</b>
                          <span class="title-block">{{ list.name }}</span>
                        </span>
                        <span class="col-md-1 col-sm-2 col-lg-1 text-left">
                          <img v-if="list.language === 'en_US'" :class="'flag'" src="/assets/img/flags/eua.svg"
                            :alt="list.language" />
                          <img v-else-if="list.language === 'es'" :class="'flag'" src="/assets/img/flags/espanha.svg"
                            :alt="list.language" />
                          <img v-else-if="list.language === 'pt_BR'" :class="'flag'" src="/assets/img/flags/brasil.svg"
                            :alt="list.language" />
                          <img v-else-if="list.language === 'zh_CN'" :class="'flag'" src="/assets/img/flags/cn.svg"
                            :alt="list.language" />
                          <img v-else-if="list.language === 'de'" :class="'flag'" src="/assets/img/flags/de.svg"
                            :alt="list.language" />
                          <img v-else-if="list.language === 'fr'" :class="'flag'" src="/assets/img/flags/fr.svg"
                            :alt="list.language" />
                          <img v-else-if="list.language === 'pt_PT'" :class="'flag'" src="/assets/img/flags/pt.svg"
                            :alt="list.language" />
                          <span v-else class="material-icons-outlined undefined_lang">question_mark</span>
                          <!-- <span>{{ list.language }}</span> -->
                        </span>
                        <div class="col-md-2 col-sm-3 col-lg-2 text-left">
                          <span v-if="list.status == 'SUBMITTED'" class="badge badge-warning">{{
                            $tc('generic-str.status.lbl-pending', 1) }}</span>
                          <span v-if="list.status == 'CREATED'" class="badge badge-warning">{{
                            $tc('generic-str.status.lbl-pending', 1) }}</span>
                          <span v-else-if="list.status == 'ACCEPTED'" class="badge badge-warning">{{
                            $tc('generic-str.status.lbl-pending', 1) }}</span>
                          <span v-else-if="list.status == 'FAILED'" class="badge badge-danger">{{
                            $t('generic-str.status.lbl-failure') }}</span>
                          <span v-else-if="list.status == 'REJECTED'" class="badge badge-danger">{{
                            $t('generic-str.status.lbl-rejected') }}</span>
                          <span v-else-if="list.status == 'PENDING'" class="badge badge-warning">{{
                            $tc('generic-str.status.lbl-pending', 1) }}</span>
                          <span v-else-if="list.status == 'ACTIVE'" class="badge badge-success">{{
                            $t('generic-str.status.lbl-active') }}</span>
                          <span v-else-if="list.status == 'APPROVED'" class="badge badge-success">{{
                            $t('generic-str.status.lbl-active') }}</span>
                          <span class="badge badge-danger" v-else>
                            {{ list.status }}
                          </span>
                        </div>
                        <div class="col-md-2 col-sm-3 col-lg-2">
                          <span> <strong>ID:</strong>{{ list.id }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-1 col-sm-1 col-lg-1 template-opt">
                      <span :id="'visibility-n-' + i" class="material-icons visibility-icon d-none d-lg-block"
                        @click="showPreview(i, list)">visibility</span>
                      <dropdown>
                        <template v-slot:text>
                          <span class="material-symbols-outlined title">
                            more_vert
                          </span>
                        </template>
                        <template #items>
                          <button @click="callPreview('preview-new-modal', list)" class="dropdown-item">
                            Preview
                          </button>
                          <button class="dropdown-item" @click="deleteTemplate(list)">
                            {{ $t('generic-str.remove') }}
                          </button>
                        </template>
                      </dropdown>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <p class="card-text" v-if="list.body.text">{{ list.body.text }}</p>
              </div>
              <div class="card-footer">
                <div>
                  <span class="badge badge-light">
                    {{ list.category }}
                  </span>
                </div>
                <div class="row-date">
                  <span class="material-symbols-outlined">autorenew</span><span>{{ list.last_update | formatDate24
                    }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="fetched">
            <pagination :lastPage="pages" @change="fetchTemplates" />
          </div>
        </div>
        <div v-if="fetched && templates.length == 0" class="text-center w-100 no-item">
          <br><br>
          <span class="material-symbols-outlined d-block">palette</span>
          <p class="d-block">Nenhum template encontrado</p>
        </div>
        <!-- Preview Column -->
        <div v-if="fetched && templates.length" class="col-lg preview-div d-none d-lg-block">
          <div class="preview-wrapper">
            <div class="card card-preview" v-if="!showingPreview" style="background: #fff;">
              <div class="card-body">
                <p class="card-text footer-item">
                  Clique em um dos '<span class="material-icons visibility-icon">visibility</span>' para pré-visualizar
                  o
                  seu template 😀
                </p>
              </div>
            </div>
            <PreviewTemplate :template="this.template" />
            <img class="phone-img" src="@/assets/phone.svg" alt="Phone Background" />
          </div>
        </div>
        <!--/Preview Column -->
        <div class="text-center py-5 m-top-80" v-if="!fetched">
          <img src="assets/img/loading.gif">
        </div>
      </div>
    </section>
    <create-template-modal id="create-template-modal" />
    <send-template-modal id="send-template-modal" />
    <preview-new-modal id="preview-new-modal" />
  </div>
</template>

<!-- eslint-disable -->
<script>
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
import WhatsAppService from '@/services/whatsapp.service';
import Swal from 'sweetalert2';
import Pagination from '@/components/Pagination.vue';
import CreateTemplateModal from '@/components/whatsapp/CreateTemplateModal.vue';
import SendTemplateModal from '@/components/whatsapp/SendTemplateModal.vue';
import PreviewNewModal from '@/components/whatsapp/PreviewNewModal.vue';
import Dropdown from '@/components/Dropdown.vue';
import axios from '@/plugins/axios';
import PreviewTemplate from '@/components/rebranding/whatsappj/PreviewTemplate.vue';
import moment from 'moment-timezone';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';

const webApi = axios();

export default {
  name: 'WhatsAppTemplates',
  components: {
    PageHeader,
    Pagination,
    CreateTemplateModal,
    PreviewNewModal,
    SendTemplateModal,
    Dropdown,
    PreviewTemplate,
    ButtonComponent,
  },
  data() {
    return {
      breadcrumbLinks: {
        1: '/whatsapp/jv/reports/history',
        2: '/whatsapp/jv/templates',
      },
      fetchPreview: Boolean,
      searchQuery: null,
      isSending: false,
      fetched: false,
      templates: [],
      whatsNumbers: [],
      whatsapp_identification: '',
      whatsapp_phone: '',
      lastWAI: '',
      templateCategory: '',
      client: {},
      form: {
        page: 1,
      },
      pages: 1,
      waba_body: '',
      waba_header: '',
      waba_header_type: '',
      waba_footer: '',
      waba_img: '',
      waba_has_btn: Boolean,
      waba_btn_type: '',
      waba_btn_url: '',
      waba_btn_text: '',
      waba_btn_quick_replies: [],
      template: {
        buttons: {},
      },
      showingPreview: false,
      selectedTemplates: [],
      originalTemplates: [],
    };
  },
  computed: {
    selectAllTemplates: {
      get() {
        if (this.templates.length || this.templates.length !== 0) {
          return this.selectedTemplates.length === this.templates.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.templates.forEach((template) => {
            selected.push(template);
          });
        }

        this.selectedTemplates = selected;
      },
    },
    headerType() {
      return this.template?.header?.type || null;
    },
    headerBody() {
      return this.template?.header?.body || '';
    },
    customHeader() {
      const header = this.template?.header;
      return header?.type && (header?.type !== 'TEXT' || header.body?.indexOf('{{1}}') !== -1);
    },
    templateBodyParams() {
      const body = this.template?.body;

      return body && [...body?.text?.matchAll(/\{\{\d+}\}/g)].map((param, index) => {
        if (body.example_parameters[index]) return body.example_parameters[index];

        return `Parametro {{${index + 1}}}`;
      });
    },
    customizable() {
      return this.customHeader || this.templateBodyParams?.length > 0;
    },
    headerCurrentVideo() {
      if (this.headerType === 'VIDEO') {
        return this.headerVideo || this.template?.header?.['example_parameter'];
      }
      return null;
    },
    headerCurrentDocument() {
      if (this.headerType === 'DOCUMENT') {
        return this.headerDocument || this.template?.header?.['example_parameter'];
      }

      return null;
    },
    headerCurrentImage() {
      if (this.headerType === 'IMAGE') {
        return this.headerImage || this.template?.header?.['example_parameter'];
      }

      return null;
    },
    headerPreview() {
      return this.template?.header?.body?.replace(/\{\{1\}\}/, (param) => this.headerParam || param);
    },
    bodyPreview() {
      return this.template?.body?.text?.replace(/\{\{(\d+)\}\}/g, (param) => {
        const index = param.slice(2, -2) - 1;

        return this.bodyParams[index] || param;
      });
    },
    headerResults() {
      const result = this.template?.header?.body;

      return !result ? '' : result.replace(/\{\{1\}\}/, (param) => this.headerParam || '');
    },
    bodyResults() {
      return this.template?.body?.text?.replace(/\{\{(\d+)\}\}/g, (param) => {
        const index = param.slice(2, -2) - 1;

        return this.bodyParams[index] ?? null;
      });
    },
    footerBody() {
      return this.template?.footer?.body;
    },
  },
  created() {
    this.$root.$on('template.submit', this.fetch);
    this.getWhatsNumbers();
  },
  methods: {
    changeCategory(category) {
      this.templateCategory = category === this.templateCategory ? '' : category;
      this.templates = this.originalTemplates;
      if (this.templateCategory !== '') {
        this.templates = this.originalTemplates.filter(template => (template.category === category));
      }
    },
    deleteAll() {
      const arrayPush = [];
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: `Tem certeza que deseja remover ${this.selectedTemplates.length} templates?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.fetched = false;
          this.selectedTemplates.forEach((template) => {
            arrayPush.push(
              WhatsAppService.deleteNewTemplate(template.id, this.form),
            );
          });
          this.selectedTemplates = [];
          Promise.all(arrayPush)
            .then(
              (responses) => {
                console.log(responses);
                this.$toast.show({
                  title: this.$t('generic-str.success'),
                  content: 'Templates removidos com sucesso',
                  type: 'success',
                });
                this.fetchTemplates(1);
              },
              (error) => {
                this.content = error;
                this.$toast.show({
                  title: this.$t('sms.infos.filters.status.lbl-error'),
                  content: 'Erro interno, por favor, tente novamente mais tarde.',
                  type: 'danger',
                });
              },
            )
            .finally(() => {
              this.fetched = true;
            });
        }
      });
    },
    showPreview(previewIndex, data) {
      this.fetchPreview = false;
      this.showingPreview = true;
      const previewValue = previewIndex;
      this.template = data;
      console.log('Preview: ', data);

      const elems = document.querySelectorAll('.visibility-icon');
      [].forEach.call(elems, (el) => {
        el.classList.remove('active');
      });
      const visibilityIcon = document.getElementById(`visibility-n-${previewIndex}`);
      visibilityIcon.classList.add('active');

      this.fetchPreview = true;
      this.template.type = 'DEFAULT';
      if (this.template.buttons) {
        if (this.template.buttons.type === 'CATALOG') {
          this.template.button_index = 0;
          this.template.type = 'CATALOG';
          this.template.button_parameter = [];
        }
        if (this.template.buttons.type === 'COPY_CODE') {
          this.template.button_index = 0;
          this.template.type = 'CUPOM';
          this.template.button_parameter = '';
        }
        if (this.template.buttons.type === 'MPM') {
          this.template.type = 'MPM';
        }
        if (this.template.limited !== undefined) {
          this.template.type = 'OFFER';
          this.template.expiration_time_ms = 1;
        }
      } else {
        this.template.buttons = {};
      }

      if (this.template.carousel) {
        this.template.type = 'CAROUSEL';
      }
    },
    changeNumber() {
      // console.log(number);
      this.whatsapp_identification = this.lastWAI;
      setTimeout(() => this.fetchTemplates(1), 100);
    },
    callModal(id) {
      const data = {
        language: 'pt_BR',
        client_id: this.client.id,
        body_examples: [],
        buttons: [],
        category: 'Account Update',
        header: { type: 'TEXT', text: '' },
      };
      this.$root.$emit('show.modal', id, data);
    },
    callPreview(id, data) {
      console.log(data);
      this.$root.$emit('show.modal', id, data);
      this.$root.$emit('updated.previewwhats', data);
    },
    fetch() {
      Promise.all([
        WhatsAppService.getClient(),
      ])
        .then(
          (responses) => {
            console.log(responses);
            this.client = responses[0].data;
            this.form.client_id = this.client.id;
            this.fetchTemplates(1);
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          // this.fetched = true;
        });
    },
    deleteTemplate(list) {
      const id = list.identification ? list.identification : list.id;
      const blockquoteStyle =
        'padding: 1rem;font-size: 0.85rem;background-color: #f2f2f2;font-style: italic;line-height: 25px;text-align: start;width: 90%;margin: 1rem auto 0;border-radius: 6px;';
      Swal.fire({
        title: 'Remover template',
        html: `Tem certeza que gostaria de remover o template:
        <blockquote style="${blockquoteStyle}">
          <strong>ID:</strong> ${id}<br>
          <strong>Nome do template:</strong> ${list.name}<br>
          <strong>Criado em:</strong> ${moment(list.creation_date).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm')}<br>
        </blockquote>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.isConfirmed) {
          this.fetched = false;
          Promise.all([
            WhatsAppService.deleteNewTemplate(id, this.form),
          ])
            .then(
              (responses) => {
                console.log(responses);
                this.$toast.show({
                  title: this.$t('generic-str.success'),
                  content: 'Templates removido com sucesso',
                  type: 'success',
                });
                this.fetchTemplates(1);
              },
              (error) => {
                this.content = error;
                this.$toast.show({
                  title: this.$t('sms.infos.filters.status.lbl-error'),
                  content: 'Erro interno, por favor, tente novamente mais tarde.',
                  type: 'danger',
                });
              },
            )
            .finally(() => {
              this.fetched = true;
            });
        }
      });
    },
    fetchTemplates(page) {
      this.fetchedItens = false;
      this.fetched = false;
      this.templates = [];
      WhatsAppService.getNewTemplates(this.whatsapp_identification, {
        params: { page, search: this.searchQuery },
      }).then((response) => {
        console.log('Templates');
        this.fetched = true;
        if (response && response.data && response.data.content) {
          // response.data.content.forEach((template) => {
          //   if (template.status !== 'DELETED') {
          //     this.templates.push(template);
          //   }
          // });
          this.templates = response.data.content;
          this.originalTemplates = this.templates;
          this.templateCategory = '';
          console.log('Search temp: ', this.templates);
        }
        this.pages = response.data?.totalPages || 0;
        this.fetchedItens = true;
      }, (error) => {
        this.content = error;
      }).finally(() => {
        this.fetchedItens = true;
      });
    },
    getWhatsNumbers() {
      this.whatsNumbers = [];
      webApi.get('whatsapp/new/numbers').then((response) => {
        console.log('WhatsNumbers', this.whatsNumbers);
        console.log(response);

        response.data.forEach((data) => {
          this.whatsNumbers.push(data);
        });

        const wai = this.whatsNumbers[0].whatsapp_identification ?? this.whatsNumbers[0].phone_number;

        this.whatsapp_identification = wai;
        this.lastWAI = wai;

        this.fetchTemplates(1);
      }, (error) => {
        this.content = error;
        this.flowLoading = false;
      });
    },
    getNumberWhatsappIdentification(number) {
      return number.whatsapp_identification ?? number.phone_number;
    },
    replaceSpaceWithUnderscore(event) {
      if (event.key === ' ') {
        event.preventDefault();
        this.searchQuery += '_';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn i {
  line-height: 1;
  color: inherit;
}

/* Rebranding */
.eye_icon {
  color: #bfc5cb;
}

.form-group {
  label {
    color: var(--foreground);
    font-size: 16px;
    margin-bottom: 20px;
  }

  input:-ms-input-placeholder {
    text-transform: lowercase !important;
  }

  input::-webkit-input-placeholder {
    text-transform: lowercase !important;
  }

  input::placeholder {
    text-transform: lowercase !important;
  }

  #inputPassword {
    border-right-color: transparent;
    border-right-width: 0;
  }

  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left: unset;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}

.darkmode .rebranding .form-control.search-form,
.rebranding .form-control.search-form {
  border-right: unset !important;
}

.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left: unset;
}

.select-all-checkbox {
  width: fit-content;
  display: flex;
  gap: 10px;
  margin: 1rem;
}

.template-opt {
  display: -webkit-box;
  display: -ms-flexbox;
  /* display: flex; */
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  /* justify-content: flex-end; */
  /* -webkit-box-align: center; */
  -ms-flex-align: center;
  /* align-items: center; */
}

.template-opt ::v-deep .dropdown a {
  display: flex;
}

.template-head-infos {
  display: flex;
  align-items: center;
}

.template-head-infos span>span {
  font-weight: 400;
}

.card-templates {
  box-shadow: none;
  border: solid 1px #E4E7EC;
}

.card-templates .card-text {
  width: fit-content;
  background-color: #f2ecfd;
  padding: 4px 10px;
}

.darkmode .card-templates .card-text {
  background-color: var(--background-3);
}

.card-templates .card-footer {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.card-templates .card-body {
  padding-top: 10px;
  padding-bottom: 10px;
}

.card-templates .card-footer small {
  margin-left: auto;
  color: var(--gray-font-color);
}

.icon-success {
  color: #6eda2c;
}

.icon-failed {
  color: #ff0000;
}

/* Phone preview */
.preview-div {
  display: flex;
  // position: relative;
  /* align-items: center; */
  position: sticky;
  top: 100px;
  height: fit-content;
}

.preview-wrapper {
  width: 100%;
  height: fit-content;
}

.phone-img {
  margin-left: auto;
  width: 20rem;
  height: fit-content;
}

.visibility-icon {
  color: var(--gray-font-color);
  cursor: pointer;
  transition: .5s;
}

.visibility-icon.active {
  color: var(--clr-yup-purple) !important;
}

.card-preview {
  .footer-item {
    font-size: 1.15rem;

    span.material-icons {
      transform: translateY(5px);
    }
  }
}

@media (min-width: 768px) {
  ::v-deep label {
    font-size: 1rem !important;
  }

  .phone-img {
    position: absolute;
    right: 0;
    padding-right: 3rem;
    // margin-top: 10%; // Position-sticky
    z-index: -1;
  }

  .preview-wrapper {
    /* position: sticky;
    top: 100px; */
    display: flex;
    align-items: center;
    height: 512px;
  }

  .card-preview {
    position: absolute;
    width: 410px;
    // top: 200px;
    right: 20%;
    // margin-top: 4rem;
    margin-bottom: 0 !important;
  }

  .card-preview .card-body {
    padding: 1rem 1rem 2rem;

    .img-loading {
      width: 378px;
      height: 189px;
    }
  }
}

.sort-group,
.create-group {
  width: auto;
}

/* btn Preview style */
.preview-message-btns {
  border-top: 1px solid #dadde1;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  padding: 5px;
  color: #00a5f4;
  font-size: 14px;
  line-height: 20px;
}

.preview-message-btns i {
  color: #00a5f4;
}

.preview-message-btns span {
  display: flex;
  margin-bottom: 8px;
  justify-content: center;
  gap: 1rem;
  margin-top: .725rem;
}

.btn-link-preview .btn {
  box-shadow: 0px 2px 3px #0000001A;
}

.btn-delete {
  padding: 5px;
}

.flag {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  max-width: 20px;
}

.undefined_lang {
  border: 2px solid var(--foreground);
  border-radius: 50%;
  padding: .1rem;
}

.image-container {
  text-align: center;
  margin-bottom: 10px;
  background: #eff2f7;
  padding: 25px
}

.image-container img {
  width: 20%;
}

.title-block {
  width: 280px;
  display: inline-block;
}

.flag {
  width: 20px;
  height: 20px;
}

.btn-add-template {
  background: none !important;
  border: none !important;
  color: var(--clr-yup-purple) !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  display: flex !important;
  padding: 0px !important;
  flex-direction: row-reverse;
}

.span-text {
  margin-right: 5px;
}

.p-0 {
  padding: 0px !important;
}

.row-date {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.row-date span {
  color: #868fa1;
  font-weight: 400;
  font-size: 14px;
}

.row-date .material-symbols-outlined {
  font-size: 18px;
  margin-top: 2px;
}

.m-top-80 {
  margin-top: 80px;
}

.btn-wrapper {
  display: flex;
  align-items: center;
}

/* Preview */
.preview-wrapper {
  position: sticky;
  top: 90px;
}

.card-preview {
  padding: 10px;
  border-radius: 8px;
}

.card-preview .card {
  box-shadow: none !important;
  border-radius: 15px !important;
  overflow: hidden;
}

.card-preview .card-body {
  padding: 8px !important;
}

.card-preview .media {
  overflow: hidden;
  border-radius: 8px;
}

.type-row .card {
  flex: 1;
  margin: 3px;
}

.image-default-header {
  height: 150px;
  width: 100%;
  object-fit: cover;
}

.type-row span.material-symbols-outlined {
  font-size: 25px !important;
}

.btn-whats-message {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border-top-color: #e0e0e0 !important;
  border-top-width: 1px !important;
  border-top: solid;
}

.btn-whats-message a {
  display: block;
  justify-content: center;
  padding: 5px;
  color: #389dc9 !important;
  font-weight: 500;
  font-size: 15px;
  margin-right: 5px;
  margin-top: 3px;
}

.btn-whats-message a span {
  font-size: 15px;
  margin-top: 5px;
  margin-right: 5px;
}

.card-img {
  padding: 5px;
}

.card-img-top {
  border-radius: 10px;
}

.card-catalog .card-img {
  background: #f6f6f6;
  padding: 5px;
}

.card-catalog .prod-preview {
  display: flex;
  height: auto;
}

.prod-preview img {
  padding: 10px;
  width: 70px;
  background: #000;
}

.prod-preview h3 {
  font-size: 16px;
  margin: 0;
  margin-left: 10px;
}

.prod-preview p {
  color: #adadad;
  font-weight: 100;
  margin-left: 10px;
  margin-bottom: 0px;
}

.card-catalog .card-text {
  margin-bottom: 0px;
}

.footer-item {
  font-weight: 600;
  margin-bottom: 10px;
  padding-bottom: 0px;
}

.card-text {
  padding-bottom: 15px;
}

.time-span {
  position: absolute;
  right: 10px;
  color: #ccc;
  font-size: 13px;
  bottom: 5px;
}

.type-row .card-header.active {
  background: #752de6 !important;
}

.type-row .card-header:hover {
  background: #d0d0d0 !important;
}

.type-row .card-header.active button span {
  color: #fff !important;
}

.type-row .card-header.active button .wrapper-title p {
  color: #fff;
}

.offer-icon {
  width: 50px;
  height: 50px;
  background: #a0f7b3;
  border-radius: 50%;
  margin-left: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.offer-icon span {
  color: #47ad5e;
}

.offer-code {
  color: #c6c6c6;
}

.padding-b-0 {
  padding-bottom: 0px !important;
}

.padding-t-0 {
  padding-top: 0px !important;
}

.btn-whats-message-coupon a span {
  font-size: 15px !important;
  margin-top: 3px !important;
  margin-right: 5px;
}

.btn-whats-message-coupon a {
  font-size: 13px !important;
}

.h-160 {
  height: 160px !important;
}

.card-img-top {
  object-fit: cover;
  background: #000;
}

.video-header {
  width: 271px;
  height: 180px;
  background: #000;
}

.toggle-check .control .control_indicator {
  margin-right: 0px !important;
}

.edit_square_icon {
  font-size: 18px;
  margin: 8px;
}

.img-upload-icon {
  position: absolute;
  right: 30px;
  top: 15px;
}

.img-edit-icon {
  position: absolute;
  right: 30px;
  top: 45px;
}

.img-upload-icon span i {
  color: #fff;
}

.input-example-card {
  font-size: 10px;
  height: 25px;
}

.color-bcbcbc {
  color: #bcbcbc !important;
}

.card-preview {
  // position: absolute; // Position-sticky
  position: relative;
  width: 410px;
  // top: 200px; // Position-sticky
  // right: 20%; // Position-sticky
  right: -20px;
  margin-left: auto;
  margin-bottom: 1rem;
  min-height: 93px;
  --gap: 0.5rem;
  background: #efe7dc;
  border: solid 1px #e0e0e0;
}

.card-preview .card-body {
  padding: 1rem 1rem 2rem;
  display: flex;
  flex-direction: column;

  video {
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
  }

  img,
  object {
    margin-left: auto;
    margin-right: auto;
  }

  .img-loading {
    width: 378px;
    height: 189px;
  }
}

.card-preview {
  overflow-y: auto;
  max-height: 500px;
}

.btn-delete-all {
  font-size: 14px;
  color: #595959;
}

.btn-delete-all .material-symbols-outlined {
  font-size: 20px;
}

.action {
  padding: 0.5rem;
  padding-left: 0;

  *,
  button {
    font-weight: normal !important;
    color: #697077 !important;
  }

  button {
    background: transparent;
    border: none;
  }

  .control-group,
  .material-symbols-outlined {
    margin-right: 0.5rem;
  }
}

.all-actions .action {
  float: left;
}

.w-100 {
  width: 100% !important;
}

.no-item span {
  font-size: 70px !important;
  color: #bababa;
  margin-top: 20px;
}

.no-item p {
  font-size: 20px;
  color: #bababa;
}

.p-0 {
  padding: 0px;
}

.accordion-wpp-template {
  margin-top: .5rem;
  margin-bottom: 2rem;
  display: flex;

  .card {
    background-color: #F9FAFB;
    border-color: #E5E5E5;
    border-width: 1px !important;
    border-style: solid;
    box-shadow: none;
    margin-bottom: 1.25rem;
    flex: 1;
    margin: 5px;

    .card-header {
      display: flex;
      height: auto;
      align-items: center;
      padding: 10px;
      background-color: #F2F4F7;
      border-color: #E5E5E5;
    }
  }

  .card .card-header:hover {
    background: #f5eefc;
  }

  .card .card-header.active {
    background: #752de6;
  }

  .card .card-header .material-symbols-outlined {
    font-size: 30px !important;
  }

  .card .card-header.active .material-symbols-outlined {
    color: #ffffff;
    font-size: 30px !important;
  }

  .btn-wrapper-opt {
    width: 100%;
    display: flex;
    height: fit-content;
    align-items: center;
    gap: .5rem;
    position: relative;
    z-index: 1;

    span.material-symbols-outlined {
      font-size: 45px;
      color: #667085;
      margin-right: 1rem;
    }

    .btn-template {
      height: auto;
      padding: 0;
      font-weight: 400;
      width: 100%;
      text-align: center;

      .wrapper-title {
        display: flex;
        flex-direction: column;
        text-align: start;

        p.title-opt {
          font-size: .875rem;
          margin-bottom: .25rem;
        }

        span {
          white-space: break-spaces;
          font-size: .75rem;
          color: #595959;
        }
      }
    }

    .toggle-check {
      margin-left: auto;
      position: relative;
      z-index: 0;

      .control .control_indicator {
        order: 1;
        margin-left: auto;
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }
}
</style>
